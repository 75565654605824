.App {
  text-align: center;
  max-width:100vw;
  overflow:hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  max-width: 90vw;
}

.App-header {
  background-color: #000;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Content {
  margin: 10px;
  max-height: 300px;
  max-width: 100vw;
}

.clickable {
  cursor: pointer;
}

.Shows {
  overflow-x: auto;
  white-space: nowrap;
  padding: 25px;
}

.Show-Header {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.bandcamp-player {
  position: relative;
  width: 50%;
  margin: auto;
  padding:50px;
}

.Youtube {
  max-width:100vw;
}

@media only screen and (max-width: 625px){
  .bandcamp-player {
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: 3vh;
  }

  .About, .Lineup {
    font-size: 4vmin;
    max-width: 100vw;
    white-space: wrap;
  }

  .App-Header {
    max-width: 100vw;
    padding:10px;
  }
}